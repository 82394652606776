import videojs from 'video.js';
import { registerIVSQualityPlugin, registerIVSTech } from 'amazon-ivs-player'; // @ts-ignore

import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

var createAbsolutePath = function createAbsolutePath(assetPath) {
  return new URL(assetPath, document.URL).toString();
};

registerIVSTech(videojs, {
  wasmWorker: createAbsolutePath(wasmWorkerPath),
  wasmBinary: createAbsolutePath(wasmBinaryPath)
});
registerIVSQualityPlugin(videojs);
var player = videojs('videojs-player', {
  fill: true,
  bigPlayButton: true,
  techOrder: ["AmazonIVS"],
  autoplay: false,
  defaultVolume: 1,
  poster: createAbsolutePath('/poster.png')
}, function () {
  console.warn('Player is ready to use');
});
player.enableIVSQualityPlugin();
var events = player.getIVSEvents();
var ivsPlayer = player.getIVSPlayer();
ivsPlayer.addEventListener(events.PlayerState.PLAYING, function () {
  console.log('IVS Player is playing');
}); // const PLAYBACK_URL = 'https://84cdea66c496.us-east-1.playback.live-video.net/api/video/v1/us-east-1.165178837030.channel.WOmpgYVYQfG1.m3u8'

var REC_URL = 'https://rec.cool4code.com/v1/165178837030/WOmpgYVYQfG1/2024/4/6/23/17/GM3NQvGa5yeH/media/hls/master.m3u8';
player.src(REC_URL);